<template>
  <div>
    <card-details
      class="mb-5"
      :field="dataParams.field_name"
      :date="dataParams.reservation_date"
      :time="computedTime"
      :sport="dataParams.sport_name"
      :origin="dataParams.origin"
      :price="dataParams.reservation_type === 'flat' ? dataParams.reservation_price : dataParams.price_per_player"
      :reservation-type="dataParams.reservation_type"
    />

    <div
      v-if="dataParams.include_video === 'Y'"
      class="mb-5"
    >
      <label class="font-weight-medium">Add-ons</label>
      <v-card
        color="#F5F5F5"
        class="d-flex align-center mt-2"
        :width="$vuetify.breakpoint.smAndUp ? '320px' : '100%'"
        height="62px"
      >
        <v-img
          v-if="dataParams.ivideo_thumbnail"
          :src="dataParams.ivideo_thumbnail"
          max-width="100"
          cover
        />
        <div
          v-else
          :style="'height: 62px; width: 155px; max-width: 100px; background-color: #9B9B9B; position: relative;'"
        >
          <v-icon
            size="25"
            color="white"
            :style="`
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            `"
          >
            {{ icons.mdiVideoOutline }}
          </v-icon>
        </div>
        <v-card-text class="d-flex flex-column py-0">
          <span class="text-body-1">{{ $t('reservations.video_recording') }}</span>
          <span
            v-if="dataParams.include_video === 'Y'"
            class="text-sm"
          >{{ dataParams.video_price ? formatCurrency(dataParams.video_price) : '$0.00' }}</span>
          <span
            v-else
            class="text-sm"
          >{{ $t('reservations.no_included') }}</span>
        </v-card-text>
      </v-card>
    </div>

    <!-- remove challenge -->
    <participants
      class="mb-5"
      :is-open="computedIsOpen"
      :is-refresh="isRefresh"
      :edit-mode="true"
      :participants.sync="participants"
      :participants-not-going.sync="computedDataParams.players_not_going"
      :edit-participants.sync="computedDataParams.players"
      :facility="configFacility"
      :players-qty="computedField"
      :players-qty-booking="playersQtyLocal || 1"
      :per-participant-price="perParticipantPrice"
      :remaining-amount="remainingAmount"
      :is-validate-form="false"
      :is-challenge="computedDataParams.is_challenge === 'Y'"
      :payment-type="computedDataParams.reservation_type"
      :data-params="computedDataParams"
      @updateLocal="val => updatePlayersQtyLocal(val)"
      @selectPlayersQty="val => playersQtySelected = val"
      @recalculatePayment="calPricePerPlayer"
      @refreshDetails="(e) => $emit('refreshDetails', e)"
    />
  </div>
</template>

<script>
import {
  ref, computed, watch, getCurrentInstance,
} from '@vue/composition-api'
import { formatCurrency, formatStdDate } from '@core/utils'
import useSelectOptions from '@core/utils/useSelectOptions'
import { recalculateReservationPayment } from '@api'
import { mdiVideoOutline } from '@mdi/js'

import CardDetails from '@/views/reservations/components/CardDetails.vue'
import Participants from '@/views/reservations/components/Participants.vue'

export default {
  components: {
    CardDetails,
    Participants,
  },
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    dataParams: {
      type: [Object, null],
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    isRefresh: {
      type: Boolean,
      default: false,
      required: false,
    },
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { configFacility, configObjFacility } = useSelectOptions()
    const computedDataParams = computed(() => props.dataParams)
    const computedTime = computed(() => {
      if (computedDataParams.value.time_str) {
        const [t1, t2] = computedDataParams.value.time_str.split('-')

        return `${formatStdDate(t1, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale)}-${formatStdDate(t2, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale)}`
      }

      return ''
    })
    const computedIsOpen = computed(() => props.isOpen)
    const computedField = computed(() => {
      if (computedDataParams.value.field_id) return props.fields.find(e => e.id === computedDataParams.value.field_id).players_qty

      return 1
    })

    const playersQtyLocal = ref(0)
    const playersQtySelected = ref(null)
    const computedRefresh = computed(() => props.isRefresh)
    const perParticipantPrice = ref(0)
    const remainingAmount = ref(0)
    const participants = ref([{
      id: null,
      user_match_id: null,
      contact_id: null,
      user_app_id: null,
      fullname: null,
      phone_number: null,
      email: null,
      final_amount: perParticipantPrice.value || 0,
      paid: 'N',
      payment_medium: null,
      onsite_payment_method_id: null,
      onsite_payment_method_name: null,
      empty: true,
      enabled: true,
      is_organizer: 'Y',
      avatar: null,
      checked: false,
    }])

    const calPricePerPlayer = async () => {
      const playersPaid = participants.value.filter(e => e.paid === 'Y' || e.edited)
      const amountPaid = playersPaid.reduce((a, b) => a + b.final_amount, 0)
      const playersPaidQty = playersPaid.length

      let resp = null
      resp = await recalculateReservationPayment({
        field_id: computedDataParams.value.field_id,
        reservation_time: computedDataParams.value.reservation_time_int,
        price: computedDataParams.value.reservation_price || 0,
        include_video: computedDataParams.value.include_video,
        players_qty: playersQtyLocal.value || computedDataParams.value.players_qty,
        players_paid_qty: playersPaidQty,
        amount_paid: amountPaid,
        reservation_type: computedDataParams.value.reservation_type,
        reservation_id: computedDataParams.value.reservation_id,
      })

      if (resp && resp.ok) {
        perParticipantPrice.value = resp.data.players_final_amount
        computedDataParams.value.balance = resp.data.reservation_balance
        remainingAmount.value = (resp.data.balance) || 0
      } else {
        perParticipantPrice.value = 0
      }
    }

    const updatePlayersQtyLocal = val => {
      playersQtyLocal.value = val
    }

    watch([computedRefresh], () => {
      if (computedRefresh.value) {
        // perParticipantPrice.value = props.dataParams.price_per_player || 0
      }
    })

    watch([computedIsOpen], () => {
      perParticipantPrice.value = props.dataParams.price_per_player || 0
      playersQtyLocal.value = props.dataParams.players_qty || 1
      participants.value = [{
        id: null,
        user_match_id: null,
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: perParticipantPrice.value || 0,
        paid: 'N',
        payment_medium: null,
        onsite_payment_method_id: null,
        onsite_payment_method_name: null,
        empty: true,
        enabled: true,
        is_organizer: 'Y',
        avatar: null,
        checked: false,
      }]
      remainingAmount.value = (computedDataParams.value.balance * -1) || 0
    })

    return {
      configFacility,
      computedDataParams,
      computedField,
      computedIsOpen,
      computedTime,
      participants,
      playersQtySelected,
      perParticipantPrice,
      remainingAmount,
      playersQtyLocal,

      formatStdDate,
      formatCurrency,
      calPricePerPlayer,
      updatePlayersQtyLocal,

      icons: {
        mdiVideoOutline,
      },
    }
  },
}
</script>

<style>

</style>
