<template>
  <v-card
    id="reservation-list"
    class="mb-8"
  >
    <v-card-title>
      {{ t('tooltip.filters') }}
    </v-card-title>

    <v-row
      class="px-2 ma-0 text-center"
    >
      <v-col
        cols="12"
        sm="3"
      >
        <v-select
          v-model="fieldReservations"
          :placeholder="t('fields.field')"
          :items="fieldsOptions"
          item-text="name"
          item-value="id"
          outlined
          clearable
          dense
          hide-details
        ></v-select>
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="statusReservations"
          :items="statusOptions"
          item-text="text"
          item-value="value"
          single-line
          outlined
          dense
          hide-details
          clearable
          :placeholder="t('select.status')"
        ></v-select>
      </v-col>

      <!-- <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="statusPaymentReservations"
          :items="statusPaymentOptions"
          item-text="text"
          item-value="value"
          :placeholder="t('reservations.payment_status')"
          single-line
          outlined
          dense
          hide-details
          clearable
        ></v-select>
      </v-col> -->

      <v-col
        cols="12"
        sm="2"
      >
        <v-menu
          v-model="modalFromReservations"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computerDateFrom"
              :placeholder="t('transactions.from_date')"
              :prepend-icon="icons.mdiCalendar"
              outlined
              readonly
              dense
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFromReservations"
            color="secondary"
            :max="dateToReservations ? dateToReservations : null"
            @input="modalFromReservations = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        cols="12"
        sm="2"
      >
        <v-menu
          v-model="modalToReservations"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computerDateTo"
              :placeholder="t('transactions.to_date')"
              :prepend-icon="icons.mdiCalendar"
              outlined
              readonly
              dense
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateToReservations"
            color="secondary"
            :min="dateFromReservations ? dateFromReservations : null"
            @input="modalToReservations = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-divider />

    <v-row
      justify="start"
      class="px-2 ma-0"
    >
      <v-col
        cols="12"
        sm="12"
        md="3"
      >
        <v-text-field
          v-model="searchQueryReservations"
          :placeholder="t('search')"
          outlined
          hide-details
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider />

    <v-data-table
      :headers="computedTableColumnsReservations"
      :items="dataListTableReservations"
      item-key="reservation_id"
      :item-class="() => 'cursor-pointer'"
      :loading="loadingReservations"
      :options.sync="optionsReservations"
      :loading-text="t('table.loading_text')"
      :no-data-text="t('table.no_data')"
      :header-props="{sortByText: t('table.sort_by')}"
      :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"
      :items-per-page="-1"
      :search.sync="searchQueryReservations"
      :sort-desc.sync="descSortReservations"
      :server-items-length="totalDataListTableReservations"
      @click:row="onShow"
    >
      <template #[`item.contact_name`]="{item}">
        <span v-if="!item.contact_name || (item.contact_name && !item.contact_name.toLowerCase().includes('none'))">{{ item.contact_name }}</span>
        <span v-else>{{ t('reservations.without_contact') }}</span>
      </template>

      <template #[`item.date_str`]="{item}">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="font-weight-semibold text-truncate">{{ formatStdDate(`${item.reservation_date} ${item.start_time}`, 'datetime-list', configObjFacility.is12Hour, $i18n.locale) }}</span>
            <span
              class="text-xs"
            >
              {{ t('videos.video') }} ID: {{ item.video_id || '' }}
            </span>
          </div>
        </div>
      </template>

      <template #[`item.reservation_time`]="{item}">
        {{ `${item.reservation_time} Mins` }}
      </template>

      <template #[`item.payment_status_str`]="{item}">
        <v-chip
          small
          :color="resolveStatusPaymentVariant(item.payment_status)"
          :class="`${resolveStatusPaymentVariant(item.payment_status)}--text`"
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ item.payment_status_str }}
        </v-chip>
      </template>

      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="resolveStatusVariant(item.status)"
          :class="`${resolveStatusVariant(item.status)}--text`"
          class="v-chip-light-bg font-weight-semibold text-capitalize"
        >
          {{ item.status_str }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{item}">
        <v-tooltip
          bottom
        >
          <template #activator="{ on: tooltip, attrs }">
            <v-icon
              size="20"
              class="me-2"
              color="info"
              v-bind="attrs"
              v-on="{ ...tooltip }"
              @click="onShow(item)"
            >
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </template>
          <span>{{ t('tooltip.detail') }}</span>
        </v-tooltip>

        <!-- <v-tooltip
          v-if="item.status === 'A'"
          bottom
        >
          <template #activator="{ on: tooltip, attrs }">
            <v-icon
              size="20"
              class="me-2"
              color="error"
              v-bind="attrs"
              v-on="{ ...tooltip }"
              @click="onCancel(item.id)"
            >
              {{ icons.mdiCancel }}
            </v-icon>
          </template>
          <span>{{ t('tooltip.cancel') }}</span>
        </v-tooltip> -->
      </template>
    </v-data-table>

    <!-- Show/Edit Reservation Form -->
    <v-navigation-drawer
      v-model="isReservationDetailOpen"
      :right="!$vuetify.rtl"
      persistent
      touchless
      app
      temporary
      :permanent="isReservationDetailOpen"
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '65%'"
    >
      <reservation-detail-view
        :is-open.sync="isReservationDetailOpen"
        :data-params="calendarEventDetail"
        :reasons="cancellationReasons"
        :fields="reservationsFieldsOptions"
        :is-refresh="isRefresh"
        @openClose="openCloseDetail"
        @refreshEvents="onRefreshEvents"
        @refreshDetails="onRefreshEventDetail"
        @share="onUpdateShare"
        @recurring="onRecurring"
      />
    </v-navigation-drawer>

    <share
      :is-open.sync="isDialogShare"
      :icon-size="28"
      :link="shareData.link"
      :sport="shareData.sport"
      :date="shareData.date"
      :start-time="shareData.startTime"
      :location="shareData.location"
      @open-close="openUpdateShare"
    />
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-named-as-default-member */
import {
  mdiPlus,
  mdiCancel,
  mdiCalendarSync,
  mdiEyeOutline,
} from '@mdi/js'
import { ref, computed, watch, onMounted, getCurrentInstance, inject } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatStdDate } from '@core/utils'

import { cancelRecurringReservation } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import ReservationDetailView from '@/views/reservations/detail/ReservationDetailView.vue'
import Share from '@/views/components/share/Share.vue'

import useReservations from '@/views/reservations/useReservations'
import useReservationList from './useReservationList'

export default {
  components: {
    ReservationDetailView,
    Share,
  },
  setup() {
    const { t, tInContext } = useUtils()

    // router
    const { route } = useRouter()
    const vm = getCurrentInstance().proxy

    const {
      dataListTableReservations,
      computedTableColumnsReservations,
      totalDataListTableReservations,
      searchQueryReservations,
      statusReservations,
      statusPaymentReservations,
      fieldReservations,
      modalFromReservations,
      modalToReservations,
      dateFromReservations,
      dateToReservations,
      loadingReservations,
      descSortReservations,
      optionsReservations,

      filters,
      maxDate,
      fieldsOptions,
      configFacility,
      configObjFacility,
      configOrganization,

      fetchFields,
      fetchReservations,
      resolveStatusVariant,
      resolveStatusPaymentVariant,
      resolveStatusText,
      resolveWeekdays,
      hasPermission,
      setFilters,
      setOptions,
      getFilterBySubmodule,
    } = useReservationList()

    const currentTab = inject('currentTab')

    const {
      calendarEventDetail,
      isRefresh,
      fieldsOptions: reservationsFieldsOptions,
      cancellationReasons,

      fetchFields: fetchReservationsFields,
      fetchEventNoReason,
      fetchCancellationReasons,
      fetchEvents,
      fetchEventDetail,
    } = useReservations(false)

    const isReservationDetailOpen = ref(false)
    const isDialogShare = ref(false)
    const shareData = ref({
      link: '',
      sport: '',
      date: '',
      startTime: '',
      location: '',
    })

    const computerDateFrom = computed(() => (dateFromReservations.value ? formatStdDate(dateFromReservations.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale) : null))
    const computerDateTo = computed(() => (dateToReservations.value ? formatStdDate(dateToReservations.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale) : null))

    const statusOptions = computed(() => [
      { text: t('status.confirmed'), value: 'F' },
      { text: t('status.cancelled'), value: 'C' },
    ])

    const statusPaymentOptions = computed(() => [
      { text: t('status.pending'), value: 'N' },
      { text: t('status.hold'), value: 'H' },
      { text: t('status.paid'), value: 'P' },
    ])

    const onCancel = async id => {
      const customTitle = `${tInContext('questions.are_you_sure', vm)}`
      const customConfirm = `${tInContext('reservations.delete_all_pending', vm)}`
      Swal.fire({
        title: customTitle,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: customConfirm,
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await cancelRecurringReservation(id)
          if (response.ok) {
            success(response.message)
            fetchReservations()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    const openCloseDetail = value => {
      isReservationDetailOpen.value = value
    }

    const onOpenEventDetail = async id => {
      await fetchEventDetail(id)
      isReservationDetailOpen.value = true
    }

    const openUpdateShare = async val => {
      isDialogShare.value = val
    }

    const onRefreshEvents = async () => {
      console.log('🚀 ~ onRefreshEvents')

      // if (configFacility.value) await fetchEventNoReason(configFacility.value)
      // isReservationNewOpen.value = false
      // newReservationEditable.value = false
      // datePassed.value = false
      // await fetchEvents(configFacility.value || 0)
    }

    const onRefreshEventDetail = async id => {
      console.log('🚀 ~ onRefreshEventDetail ~ id:', id)

      // isReservationNewOpen.value = false
      // newReservationEditable.value = false
      // datePassed.value = false
      // await fetchEventDetail(id)
    }

    const onUpdateShare = item => {
      shareData.value.link = item.reservation_share_url
      shareData.value.sport = item.sport_name
      shareData.value.date = item.date_str
      shareData.value.startTime = item.time_str_short
      shareData.value.location = `${item.facility_name} • ${item.field_name}`

      openUpdateShare(true)
    }

    const onRecurring = async item => {
      console.log('🚀 ~ onRecurring ~ item:', item)
    }

    const onShow = async item => {
      await onOpenEventDetail(item.reservation_id)

      // router.push({
      //   name: 'views-reservations-form',
      //   params: {
      //     id: item.reservation_id,
      //     dataParams: item,
      //     option: 2,
      //     pageParams: optionsReservations.value,
      //     filterParams: filters.value,
      //   },
      // })
    }

    watch([currentTab], async () => {
      if (currentTab.value === 1) {
        setTimeout(async () => {
          await fetchReservations()
        }, 700)
      }
    }, { immediate: false })

    onMounted(async () => {
      const reservationFilters = getFilterBySubmodule('views-recurring-reservation-list', 'reservations')

      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      await fetchFields(configFacility.value)
      await fetchReservationsFields(configFacility.value)
      await fetchReservations()
      await fetchCancellationReasons(configOrganization.value)

      if (reservationFilters.search) searchQueryReservations.value = reservationFilters.search
      if (reservationFilters.field) fieldReservations.value = reservationFilters.field
      if (reservationFilters.dateFrom) dateFromReservations.value = reservationFilters.dateFrom
      if (reservationFilters.dateTo) dateToReservations.value = reservationFilters.dateTo
      if (reservationFilters.status) statusReservations.value = reservationFilters.status
      if (reservationFilters.statusPayment) statusPaymentReservations.value = reservationFilters.statusPayment
      if (reservationFilters.option) optionsReservations.value = reservationFilters.option
    })

    return {
      statusOptions,
      statusPaymentOptions,

      dataListTableReservations,
      computedTableColumnsReservations,
      totalDataListTableReservations,
      searchQueryReservations,
      statusReservations,
      statusPaymentReservations,
      fieldReservations,
      modalFromReservations,
      modalToReservations,
      dateFromReservations,
      dateToReservations,
      loadingReservations,
      descSortReservations,
      optionsReservations,
      computerDateFrom,
      computerDateTo,
      configObjFacility,

      isReservationDetailOpen,
      calendarEventDetail,
      cancellationReasons,
      reservationsFieldsOptions,
      isRefresh,
      isDialogShare,
      shareData,

      filters,
      maxDate,
      fieldsOptions,

      onShow,
      onCancel,
      avatarText,
      resolveStatusVariant,
      resolveStatusPaymentVariant,
      resolveStatusText,
      resolveWeekdays,
      fetchReservations,
      hasPermission,
      setFilters,
      setOptions,
      formatStdDate,

      openCloseDetail,
      onRefreshEvents,
      onRefreshEventDetail,
      onUpdateShare,
      onRecurring,
      openUpdateShare,
      fetchEventNoReason,
      fetchEvents,
      fetchEventDetail,
      onOpenEventDetail,

      // i18n
      t,

      // icons
      icons: {
        mdiCancel,
        mdiPlus,
        mdiEyeOutline,
        mdiCalendarSync,
      },
    }
  },
}
</script>
