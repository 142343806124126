<template>
  <v-row>
    <v-col
      :cols="$vuetify.breakpoint.smAndUp ? '5' : '6'"
      :class="{'pr-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-text-field
        v-if="editMode && !computedDataParams.empty"
        v-model="computedDataParams.fullname"
        :label="label"
        :class="{'participant-label': !(computedDataParams.paid === 'N' && computedDataParams.final_amount > 0)}"
        dense
        outlined
        chips
        hide-details
        readonly
        :disabled="computedDataParams.paid === 'Y'"
      >
        <template
          v-if="computedDataParams.paid === 'N' && computedDataParams.final_amount > 0"
          v-slot:prepend-inner
        >
          <v-checkbox
            v-model="computedDataParams.checked"
            color="primary"
            class="mt-0 pt-0"
            hide-details
            dense
          ></v-checkbox>
        </template>
      </v-text-field>

      <v-autocomplete
        v-else
        :id="`autoContact-${placeIndex}`"
        ref="autoContact"
        v-model="computedDataParams.id"
        :label="resolveParticipantLabel"
        :items="participantsSearched"
        item-text="fullname"
        item-value="id"
        :loading="searchLoading"
        :search-input.sync="searchQuery"
        :filter="searchFilter"
        hide-selected
        dense
        outlined
        chips
        hide-details
        :clearable="placeIndex > 0"
        :disabled="!computedDataParams.enabled"
        @change="onSelectContact"
        @keypress.enter="() => {
          addContact()
          $refs.autoContact.blur()
        }"
        @keydown.native.backspace="onClearContact"
        @click:clear="onDeleteMatchParticipant(computedDataParams.user_match_id, computedDataParams.remove_slot)"
      >
        <template v-slot:no-data>
          <div>
            <v-list-item
              class="py-2"
              @click="() => {
                addContact()
                $refs.autoContact.blur()
              }"
            >
              <v-list-item-title>
                <a
                  href="javascript:void(0);"
                  class="secondary--text text-decoration-none text-body-1"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                    color="secondary"
                  >
                    {{ icons.mdiPlusCircleOutline }}
                  </v-icon>
                  {{ $t('reservations.add_new_participant') }}
                </a>
              </v-list-item-title>
            </v-list-item>

            <!-- remove challenge -->
            <!-- v-if="placeIndex > 0 || ['P', 'per_participant'].includes(reservationDataParams.reservation_type)" -->
            <v-list-item
              v-if="(isChallenge ? placeIndex > 1 : placeIndex > 0) || ['P', 'per_participant'].includes(reservationDataParams.reservation_type)"
              class="py-2"
              @click="() => {
                addGuest()
                $refs.autoContact.blur()
              }"
            >
              <v-list-item-title>
                <a
                  href="javascript:void(0);"
                  class="secondary--text text-decoration-none text-body-1"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                    color="secondary"
                  >
                    {{ icons.mdiAccountOutline }}
                  </v-icon>
                  {{ $t('reservations.guest') }} {{ searchQuery ? `"${searchQuery}"` : `${$t('reservations.player')} ${playerIndex + 1}` }}
                </a>
              </v-list-item-title>
            </v-list-item>
          </div>
        </template>

        <template v-slot:selection="{ attr, on, item }">
          <v-list-item-avatar
            v-if="item.id !== 0"
            class="my-2 mr-2"
            size="28"
          >
            <v-avatar
              color="primary"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="28"
            >
              <v-img
                v-if="item.avatar"
                :src="item.avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium text-body-2"
              >{{ avatarText(item.fullname) }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content
            v-if="item.id !== 0"
            class="my-2 pa-0"
          >
            <v-list-item-title v-text="item.fullname"></v-list-item-title>
            <v-list-item-subtitle
              v-if="item.phone_number || item.email"
              v-text="item.phone_number ? item.phone_number : item.email"
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              v-else
              v-text="'Guest Player'"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item-avatar
            v-if="item.id > 1"
            class="my-2 mr-2"
            size="28"
          >
            <v-avatar
              color="primary"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="28"
            >
              <v-img
                v-if="item.avatar"
                :src="item.avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium text-body-2"
              >{{ avatarText(item.fullname) }}</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content
            v-if="item.id > 1"
            class="my-2"
          >
            <v-list-item-title
              class="text-body-1"
              v-text="item.fullname"
            ></v-list-item-title>
            <v-list-item-subtitle v-text="item.phone_number ? item.phone_number : item.email"></v-list-item-subtitle>
          </v-list-item-content>

          <!-- <div v-else-if="item.id <= 1"> -->
          <div v-else-if="isChallenge ? item.id < 1 : item.id <= 1">
            <v-list-item
              v-if="item.id === 0"
              class="px-0 py-2"
            >
              <v-list-item-title>
                <a
                  href="javascript:void(0);"
                  class="secondary--text text-decoration-none text-body-1"
                  @click="() => {
                    $refs.autoContact.blur()
                  }"
                >
                  <!-- addContact() -->
                  <v-icon
                    size="20"
                    class="me-2"
                    color="secondary"
                  >
                    {{ icons.mdiPlusCircleOutline }}
                  </v-icon>
                  {{ $t('reservations.add_new_participant') }}
                </a>
              </v-list-item-title>
            </v-list-item>

            <!-- remove challenge -->
            <v-list-item
              v-if="item.id === 1 && placeIndex >= 1"
              class="px-0 py-2 test"
            >
              <v-list-item-title>
                <a
                  href="javascript:void(0);"
                  class="secondary--text text-decoration-none text-body-1"
                  @click="() => {
                    $refs.autoContact.blur()
                  }"
                >
                  <!-- addGuest() -->
                  <v-icon
                    size="20"
                    class="me-2"
                    color="secondary"
                  >
                    {{ icons.mdiAccountOutline }}
                  </v-icon>
                  {{ $t('reservations.guest') }} {{ searchQuery ? `"${searchQuery}"` : `${$t('reservations.player')} ${playerIndex + 1}` }}
                </a>
              </v-list-item-title>
            </v-list-item>
          </div>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col
      :cols="$vuetify.breakpoint.smAndUp ? '2' : '4'"
      :class="{'pr-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-text-field
        v-model.number="computedDataParams.final_amount"
        :label="$t('transactions.price')"
        class="text-body-1"
        :class="{ 'success--text': computedDataParams.paid === 'Y' }"
        outlined
        type="number"
        prefix="$"
        dense
        hide-details
        :disabled="!computedDataParams.id || !computedDataParams.enabled || disabledPrice || computedDataParams.paid === 'Y' || computedDataParams.checked"
        @input="e => {
          if (!charUnique(e, '.') || regexValidator(computedDataParams.final_amount, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
            computedDataParams.final_amount = null
            reservationPrice = 0
            fullPrice = 0
          }
          computedDataParams.edited = true
          recalculatePayment(e)
        }"
        @change="() => {
          computedDataParams.edited = true
        }"
        @keypress.enter="() => {
          computedDataParams.edited = true
        }"
        @keypress="onlyNumberDot"
      >
        <template v-slot:append>
          <div v-if="!computedDataParams.enabled || disabledPrice || computedDataParams.paid === 'Y'">
            <span
              v-if="computedDataParams.payment_medium === 'online'"
              class="text-xs"
            >
              Online
            </span>
            <span
              v-if="computedDataParams.payment_medium === 'onsite_payment_method'"
              class="text-xs"
            >
              {{ computedDataParams.onsite_payment_method_name }}
            </span>
          </div>
        </template>
      </v-text-field>
    </v-col>

    <v-col
      :cols="$vuetify.breakpoint.smAndUp ? '5' : '2'"
      class="d-flex align-start"
      block
    >
      <v-btn
        v-if="!showOnsitePayment && $vuetify.breakpoint.smAndUp"
        color="primary"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'max-width: 220px !important; min-width: 220px;'"
        :disabled="(!computedDataParams.id && placeIndex === 0)
          || !computedDataParams.enabled
          || computedDataParams.paid === 'Y'
          || ['paid', 'P'].includes(reservationDataParams.payment_status)
          || computedDataParams.checked
          || computedDataParams.final_amount === 0"
        @click="onShowOnsitePayment(!computedDataParams.id)"
      >
        <!-- i:{{ placeIndex }}-Mark as Paid -->
        {{ $t('reservations.mark_as_paid') }}
      </v-btn>

      <v-select
        v-else-if="$vuetify.breakpoint.smAndUp"
        v-model="computedDataParams.onsite_payment_method_id"
        :label="$t('settings.onSite_payment_method')"
        :items="onsitePaymentMethodsOptions"
        item-text="name"
        item-value="id"
        class="text-body-1"
        :style="$vuetify.breakpoint.smAndDown ? '' : 'max-width: 220px !important; min-width: 220px;'"
        outlined
        dense
        hide-details
        :append-icon="editMode && !!computedDataParams.onsite_payment_method_name ? null : icons.mdiMenuDown"
        :disabled="editMode && !!computedDataParams.onsite_payment_method_name"
        @change="onSelectOnsitePaymentMethod"
      />

      <v-menu
        top
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- :disabled="!computedDataParams.enabled || !computedDataParams.id || !((computedDataParams.paid === 'Y' && computedDataParams.show_invoice) || (computedDataParams.is_organizer !== 'Y'))" -->
          <v-btn
            class="ml-2 pa-0"
            color="primary"
            :style="'max-width: 38px !important; min-width: 38px; max-width: 38px'"
            :disabled="!((computedDataParams.paid === 'Y' && computedDataParams.show_invoice) || (computedDataParams.is_organizer !== 'Y' && computedDataParams.id))"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="computedDataParams.paid === 'Y' && computedDataParams.show_invoice"
            @click="onViewReceiptParticipant(computedDataParams.invoice_payment_id)"
          >
            <v-list-item-title>
              {{ $t('reservation_detail.view_receipt') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="computedDataParams.is_organizer !== 'Y' && computedDataParams.id"
            @click="onDeleteMatchParticipant(computedDataParams.user_match_id, computedDataParams.remove_slot)"
          >
            <v-list-item-title>
              {{ $t('reservation_detail.delete_participant') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>

    <v-navigation-drawer
      v-model="isContactModalOpen"
      :right="!$vuetify.rtl"
      persistent
      touchless
      app
      temporary
      width="100%"
      style="z-index: 5"
    >
      <div
        id="reservation-new-form"
        class="h-full"
      >
        <div class="drawer-header d-flex align-center mb-0">
          <span class="font-weight-semibold text-base text--primary">{{ $t('tooltip.new') }} {{ $t('contacts.contact') }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="() => {
              isContactModalOpen = false
              participantsSearched = []
            }"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="formContactDialog"
          class="pa-5"
        >
          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row
              align="center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.fullname"
                  :label="$t('users.fullname')"
                  dense
                  outlined
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.email"
                  :label="$t('users.email')"
                  dense
                  outlined
                  :rules="contactData.email ? [emailValidator] : []"
                  :disabled="!!searchedUser"
                  @input="val => {
                    if (val && emailValidator(val) === true) searchUser('email')
                  }"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                    v-model="phoneCode"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                        width: 35%;
                      "
                    :append-icon="icons.mdiMenuDown"
                    :disabled="!!searchedUser"
                    hide-details
                    :rules="[required]"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-model="phoneNumber"
                    :label="$t('users.phone')"
                    dense
                    outlined
                    :disabled="!phoneCode || !!searchedUser"
                    class="pl-1"
                    :rules="[required, validatePhone(phoneNumber, phoneCode)]"
                    @keypress="onlyNumbers"
                    @input="searchUser('phone')"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col
                v-if="searchedUser"
                cols="12"
                md="12"
              >
                <v-alert
                  v-if="searchedContact"
                  class="my-0"
                  type="warning"
                  text
                >
                  <span
                    class="font-weight-bold ml-2"
                  >{{ $t('users.has_contact') }}</span>
                </v-alert>

                <v-alert
                  class="mb-0 mt-2"
                  type="info"
                  text
                >
                  <v-avatar
                    color="primary"
                    :class="searchedUser.avatar ? '' : 'v-avatar-light-bg primary--text'"
                    size="32"
                  >
                    <v-img
                      v-if="searchedUser.avatar"
                      :src="searchedUser.avatar"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-medium"
                    >{{ avatarText(searchedUser.fullname) }}</span>
                  </v-avatar>
                  <span
                    class="font-weight-bold ml-2"
                  >{{ searchedUser.fullname }} {{ $t('users.has_user') }}</span>
                </v-alert>
              </v-col>

              <v-col
                v-if="searchedUserError"
                cols="12"
                md="12"
              >
                <v-alert
                  class="mt-0"
                  type="error"
                  text
                >
                  <span
                    class="font-weight-bold ml-2"
                  >{{ searchedUserError }}</span>
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                class="d-flex justify-start"
              >
                <v-btn
                  color="secondary"
                  class="me-3"
                  @click="onCreateContact"
                >
                  {{ $t('tooltip.save') }}
                </v-btn>

                <v-btn
                  color="secondary"
                  outlined
                  class="me-3"
                  @click="onCancelContact"
                >
                  {{ $t('tooltip.clear') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </div>
    </v-navigation-drawer>
  </v-row>
</template>

<script>
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import {
  getContactFacilitySearch, createContact, editContact, searchUserByPhoneEmail, addUserMatch, removeUserMatch, getInvoicePayment,
} from '@api'
import {
  mdiDotsVertical, mdiPlusCircleOutline, mdiAccountOutline, mdiClose, mdiMenuDown,
} from '@mdi/js'
import { avatarText, title } from '@core/utils/filter'
import {
  required, integerValidator, regexValidator, charUnique, between, emailValidator,
} from '@core/utils/validation'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import { trimSpace } from '@core/utils'
import parsePhoneNumber from 'libphonenumber-js'
import { useUtils } from '@core/libs/i18n'

// import { success, error } from '@core/utils/toasted'

export default {
  props: {
    reservationId: {
      type: Number,
      default: 0,
      required: true,
    },
    reservationType: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    placeIndex: {
      type: Number,
      default: 0,
      required: true,
    },
    playerIndex: {
      type: Number,
      default: 0,
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    reservationDataParams: {
      type: Object,
      default: () => ({
        reservation_id: null,
        recurring_id: null,
        match_id: null,
        field_id: null,
        field_name: null,
        sport_name: null,
        reservation_date: null,
        status: null,
        status_str: null,
        payment_status: null,
        payment_status_str: null,
        payment_type: null,
        reservation_type: null,
        reservation_time: null,
        reservation_time_int: null,
        start_time: null,
        end_time: null,
        video_id: null,
        video_thumbnail: null,
        include_video: null,
        time_str: null,
        price_per_player: null,
        reservation_price: null,
        video_price: null,
        others_products_price: null,
        balance: null,
        reservation_share_url: null,
        players: [],
        players_qty: null,
        players_qty_max: null,
        players_qty_min: null,
        cancel_deadline: null,
        payment_deadline: null,
        payment_medium: null,
        origin: null,
      }),
      required: true,
    },
    dataParams: {
      type: Object,
      default: () => ({
        id: null,
        user_match_id: null,
        remove_slot: false,
        contact_id: null,
        user_app_id: null,
        fullname: null,
        phone_number: null,
        email: null,
        final_amount: 0,
        paid: 'N',
        payment_medium: null,
        onsite_payment_method_id: null,
        onsite_payment_method_name: null,
        empty: true,
        enabled: true,
        is_organizer: 'N',
        avatar: null,
        checked: false,
        edited: false,
      }),
      required: true,
    },
    selectedParticipant: {
      type: Array,
      default: () => [],
      required: true,
    },
    onsitePaymentMethodsOptions: {
      type: Array,
      default: () => [],
      required: true,
    },
    disabledPrice: {
      type: Boolean,
      default: false,
      required: true,
    },
    initMode: {
      type: Boolean,
      default: false,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false,
      required: false,
    },
    perParticipantPrice: {
      type: Number,
      default: 0,
      required: true,
    },
    remainingAmount: {
      type: Number,
      default: 0,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },

    // remove challenge
    isChallenge: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions } = usePhone()
    const computedDataParams = computed(() => props.dataParams)
    const computedInitMode = computed(() => props.initMode)
    const computedEditMode = computed(() => props.editMode)
    const computedIsOpen = computed(() => props.isOpen)
    const computedRecurringType = computed(() => props.reservationType)

    const participantsSearched = ref([])
    const contactSelectedId = ref(null)
    const searchQuery = ref(null)
    const searchLoading = ref(false)
    const timerSearch = ref(null)

    const showOnsitePayment = ref(false)
    const itemsMenu = ref([
      { title: 'Delete participant', value: 1 },
      { title: 'View receipt + Delete participant', value: 2 },
    ])
    const autoContact = ref(null)
    const autoContactFocusTimer = ref(null)
    const autoContactBlurTimer = ref(null)

    const isContactModalOpen = ref(false)
    const contactSelected = ref(null)
    const contactData = ref({
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      status: 'A',
      categories_ids: [],
      contactId: null,
    })
    const formContactDialog = ref(null)

    const initCode = ref('')
    const phoneCode = ref('')
    const phoneNumber = ref('')
    const searchedUser = ref(null)
    const searchedUserError = ref(null)
    const searchedContact = ref(null)
    const timer = ref(null)
    const timerRecalculatePayment = ref(null)

    const resolveParticipantLabel = computed(() => {
      let textLabel = ''

      // remove challenge
      let isOrg = false
      if (props.isChallenge) {
        if (props.placeIndex <= 1) isOrg = true
        else isOrg = false
      } else if (!props.isChallenge) {
        if (props.placeIndex === 0) isOrg = true
        else isOrg = false
      }

      // remove challenge
      // if (props.placeIndex === 0 && ['F', 'flat'].includes(props.reservationDataParams.reservation_type)) textLabel = t('reservations.organizer')
      if (isOrg && ['F', 'flat'].includes(props.reservationDataParams.reservation_type)) textLabel = t('reservations.organizer')
      else if (computedDataParams.value.empty) textLabel = `${t('reservations.participant')} (${t('reservations.optional')})`
      else textLabel = `${t('reservations.participant')} (${t('reservations.optional')})`

      return textLabel.trim()
    })

    const recalculatePayment = async e => {
      if (timerRecalculatePayment.value) clearTimeout(timerRecalculatePayment.value)
      timerRecalculatePayment.value = setTimeout(async () => {
        if (Number.parseFloat(e) > Number.parseFloat(props.remainingAmount)) {
          computedDataParams.value.final_amount = Number.parseFloat(props.remainingAmount)
        }
        emit('recalculatePayment')
      }, 300)
    }

    const searchUser = async code => {
      if (timer.value) clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        searchedUserError.value = null
        searchedContact.value = null
        let searchPhone = 'null'
        let searchEmail = 'null'

        if (contactData.value.phone_number) searchPhone = contactData.value.phone_number
        else if (phoneNumber.value) searchPhone = phoneNumber.value
        if (contactData.value.email) searchEmail = contactData.value.email

        if (!searchedUser.value && (searchPhone !== 'null' || searchEmail !== 'null')) {
          let responseContact = null
          if (searchPhone !== 'null') {
            responseContact = await getContactFacilitySearch(props.facility || 0, searchPhone)
          }
          if ((!responseContact || (responseContact && !responseContact.ok)) && searchEmail !== 'null') {
            responseContact = await getContactFacilitySearch(props.facility || 0, searchEmail)
          }
          const response = await searchUserByPhoneEmail(searchPhone, searchEmail)
          if (response.ok) {
            searchedUser.value = response.data
            contactData.value.user_app_id = response.data.username
            if (responseContact.ok) {
              contactData.value.fullname = responseContact.data[0].fullname
              contactData.value.contactId = responseContact.data[0].id
              searchedContact.value = true
            }

            if (code === 'email') {
              const phoneNumberSearch = parsePhoneNumber(response.data.phone_number)
              phoneNumber.value = phoneNumberSearch.nationalNumber
              phoneCode.value = phoneNumberSearch.country

              contactData.value.phone_number = phoneNumberSearch.number
              contactData.value.phone_code = phoneNumberSearch.country
              contactData.value.phone_prefix = `+${phoneNumberSearch.countryCallingCode}`
            } else {
              contactData.value.email = response.data.email
            }
          } else {
            searchedUser.value = null
            contactData.value.user_app_id = null
          }
        } else {
          searchedUser.value = null
          contactData.value.user_app_id = null
        }
      }, 700)
    }

    const fetchContacts = async () => {
      if (searchQuery.value) {
        searchLoading.value = true
        const response = await getContactFacilitySearch(props.facility || 0, searchQuery.value)
        const newContact = [
          {
            id: 0,
            first_name: searchQuery.value,
            last_name: searchQuery.value,
            fullname: searchQuery.value,
            phone_number: searchQuery.value,
            email: searchQuery.value,
          },
        ]
        const newGuest = {
          id: 1,
          first_name: searchQuery.value,
          last_name: searchQuery.value,
          fullname: searchQuery.value,
          phone_number: searchQuery.value,
          email: searchQuery.value,
        }
        if (response.ok && response.data.length) {
          // if (props.placeIndex > 0) {
          // remove challenge
          if ((!props.isChallenge && props.placeIndex > 0) || (props.isChallenge && props.placeIndex > 1)) {
            newContact.push(newGuest)
          }

          const filteredArr = response.data.filter(e => !props.selectedParticipant.includes(e.id))
          participantsSearched.value = newContact.concat(filteredArr)
        } else {
          participantsSearched.value = []
        }

        // const hasNewContact = participantsSearched.value.find(e => e.id === 0)
        // console.log('🚀 ~ fetchContacts ~ hasNewContact:', hasNewContact)
        // if (!participantsSearched.value.find(e => e.id === 0)) {
        //   participantsSearched.value.unshift(newContact)
        // }

        // const hasNewGuest = participantsSearched.value.find(e => e.id === 1)
        // console.log('🚀 ~ fetchContacts ~ hasNewGuest:', hasNewGuest)
        // if (props.placeIndex > 0 && !participantsSearched.value.find(e => e.id === 1)) {
        //   participantsSearched.value.splice(1, 0, newGuest)
        // }

        searchLoading.value = false
      }
    }

    const getDataBySearch = async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        await fetchContacts()
      }, 700)
    }

    watch([searchQuery], async () => {
      if (contactSelectedId.value) {
        const firSearch = searchQuery.value
        contactSelectedId.value = null
        setTimeout(() => {
          searchQuery.value = firSearch
        }, 50)
      }
      await getDataBySearch()
    })

    const onAddMatchParticipant = async body => {
      const resp = await addUserMatch(body)
      if (resp.ok) {
        // success(resp.message)
      } else {
        // error(resp.message.text)
      }

      if (resp && resp.ok === 1) {
        return resp.data.id
      }

      return null
    }

    const addGuest = async () => {
      let valid = true
      if (props.editMode) {
        const body = {
          user_id: null,
          match_id: props.reservationDataParams.match_id,
          onsite_payment_method_id: null,
          contact_id: null,
          is_organizer: 'N',
          paid: 'N',
          fullname: searchQuery.value ? searchQuery.value : `Player ${props.playerIndex + 1}`,
          phone_number: null,
          email: null,
        }
        valid = await onAddMatchParticipant(body)
      }

      if (valid) {
        const index = new Date().getTime()
        computedDataParams.value.empty = false
        computedDataParams.value.id = `guest-${index}`
        computedDataParams.value.fullname = searchQuery.value ? searchQuery.value : `Player ${props.playerIndex + 1}`
        participantsSearched.value.push({
          id: computedDataParams.value.id,
          facility_id: null,
          first_name: null,
          last_name: null,
          fullname: computedDataParams.value.fullname,
          phone_code: null,
          phone_prefix: null,
          phone_number: null,
          email: null,
          status: null,
          user_fullname: null,
          user_app_id: null,
          avatar: null,
        })

        emit('refreshDetails')
        emit('updateNextEnabled', props.placeIndex + 1)
        emit('addGuest', props.playerIndex + 1)

        searchQuery.value = null
      }
    }

    const addContact = () => {
      isContactModalOpen.value = true
      if (formContactDialog.value) formContactDialog.value.resetValidation()
      phoneCode.value = initCode.value
      if (searchQuery.value) contactData.value.fullname = searchQuery.value ?? ''
    }

    const onSelectContact = async val => {
      if (val && val > 1) {
        const finded = participantsSearched.value.find(e => e.id === val)
        if (props.editMode) {
          const body = {
            user_id: finded.user_app_id,
            match_id: props.reservationDataParams.match_id,
            onsite_payment_method_id: null,
            contact_id: finded.id,
            is_organizer: 'N',
            paid: 'N',
            fullname: finded.fullname,
            phone_number: finded.phone_number,
            email: finded.email,
          }
          const userMatchId = await onAddMatchParticipant(body)
          computedDataParams.value.user_match_id = userMatchId
        }
        computedDataParams.value.empty = false
        computedDataParams.value.id = finded.id
        computedDataParams.value.contact_id = finded.id
        computedDataParams.value.user_app_id = finded.user_app_id
        computedDataParams.value.fullname = finded.fullname
        computedDataParams.value.phone_number = finded.phone_number
        computedDataParams.value.email = finded.email
        computedDataParams.value.email = finded.email
        computedDataParams.value.avatar = finded.avatar
        computedDataParams.value.final_amount = props.perParticipantPrice

        searchQuery.value = null

        emit('updateNextEnabled', props.placeIndex + 1)
      } else if (val === 0) {
        contactSelectedId.value = null
        addContact()
      } else if (val === 1) {
        addGuest()
      } else {
        contactSelectedId.value = null
        participantsSearched.value = []
        searchQuery.value = null
      }
    }

    const searchFilter = (item, queryText) => (item.fullname && item.fullname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      || (item.user_fullname && item.user_fullname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      || (item.phone_number && item.phone_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
      || (item.email && item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)

    const validatePhone = (e, code) => {
      if (code && e && /^([0-9])*$/.test(e)) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            contactData.value.phone_number = phoneNumberJS.number
            contactData.value.phone_code = phoneNumberJS.country
            contactData.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            return true
          }
          contactData.value.phone_number = null
          contactData.value.phone_code = null
          contactData.value.phone_prefix = null
        }
      }

      return 'Invalid number'
    }

    const onAddContact = async item => {
      let userMatchId = null
      if (props.editMode) {
        const body = {
          user_id: item.user_app_id,
          match_id: props.reservationDataParams.match_id,
          onsite_payment_method_id: null,
          contact_id: item.id,
          is_organizer: 'N',
          paid: 'N',
          fullname: item.fullname,
          phone_number: item.phone_number,
          email: item.email,
        }
        userMatchId = await onAddMatchParticipant(body)
      }

      computedDataParams.value.id = contactData.value.contactId || item.id
      computedDataParams.value.contact_id = item.id
      computedDataParams.value.user_app_id = item.user_app_id
      computedDataParams.value.user_match_id = userMatchId || item.user_match_id
      computedDataParams.value.remove_slot = item.remove_slot
      computedDataParams.value.fullname = item.fullname
      computedDataParams.value.phone_number = item.phone_number
      computedDataParams.value.email = item.email
      computedDataParams.value.final_amount = props.dataParams.final_amount
      computedDataParams.value.paid = 'N'
      computedDataParams.value.onsite_payment_method_id = null
      computedDataParams.value.empty = false

      participantsSearched.value.push({
        id: item.id,
        facility_id: item.id,
        first_name: null,
        last_name: null,
        fullname: item.fullname,
        phone_code: item.phone_code,
        phone_prefix: item.phone_prefix,
        phone_number: item.phone_number,
        email: item.email,
        status: item.status,
        user_fullname: null,
        user_app_id: item.user_app_id,
        avatar: null,
      })
      emit('updateNextEnabled', props.placeIndex + 1)
      isContactModalOpen.value = false
      if (formContactDialog.value) formContactDialog.value.reset()
    }

    const onCreateContact = async () => {
      if (formContactDialog.value.validate()) {
        const bodyContact = {
          fullname: contactData.value.fullname ? title(trimSpace(contactData.value.fullname)) : null,
          phone_code: contactData.value.phone_code,
          phone_prefix: contactData.value.phone_prefix,
          phone_number: contactData.value.phone_number,
          email: contactData.value.email,
          user_app_id: contactData.value.user_app_id,
          status: 'A',
          categories_ids: contactData.value.categories_ids,
          facility_id: props.facility,
        }
        let resp = null
        if (contactData.value.contactId) resp = await editContact(bodyContact, contactData.value.contactId)
        else resp = await createContact(bodyContact)
        if (resp.ok) {
          bodyContact.id = contactData.value.contactId || resp.data.contact_id
          onAddContact(bodyContact)

          // success(resp.message)
        } else {
          // error(resp.message.text)
          searchedUserError.value = resp.message.text
        }
      }
    }

    const onCancelContact = () => {
      if (computedDataParams.value.id === 0) computedDataParams.value.id = null
      searchQuery.value = null
      searchedUserError.value = null
      searchedContact.value = null
      searchedUser.value = null

      phoneCode.value = null
      phoneNumber.value = null
      contactData.value.fullname = null
      contactData.value.phone_number = null
      contactData.value.phone_code = null
      contactData.value.phone_prefix = null
      contactData.value.email = null
      contactData.value.status = 'A'
      contactData.value.categories_ids = []
      contactData.value.contactId = null
      formContactDialog.value.resetValidation()

      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
      phoneCode.value = initCode.value
    }

    const onSelectOnsitePaymentMethod = async onsitePaymentMethodId => {
      if (onsitePaymentMethodId === null || onsitePaymentMethodId === '*') {
        showOnsitePayment.value = false
        computedDataParams.value.onsite_payment_method_id = null
        computedDataParams.value.paid = 'N'
      } else {
        computedDataParams.value.onsite_payment_method_id = onsitePaymentMethodId
        computedDataParams.value.paid = 'Y'

        if (props.editMode) {
          computedDataParams.value.onsite_payment_method_name = props.onsitePaymentMethodsOptions.find(
            e => e.id === onsitePaymentMethodId,
          ).name
          emit('markAsPaid', computedDataParams.value)
        }
      }

      // recalculatePayment()
    }

    const onClearContact = () => {
      contactSelectedId.value = null
      showOnsitePayment.value = false

      // computedDataParams.value.final_amount = 0
      // computedDataParams.value.is_organizer = 'N'
      computedDataParams.value.id = null
      computedDataParams.value.user_match_id = null
      computedDataParams.value.remove_slot = true
      computedDataParams.value.contact_id = null
      computedDataParams.value.user_app_id = null
      computedDataParams.value.fullname = null
      computedDataParams.value.phone_number = null
      computedDataParams.value.email = null
      computedDataParams.value.paid = 'N'
      computedDataParams.value.payment_medium = null
      computedDataParams.value.onsite_payment_method_id = null
      computedDataParams.value.onsite_payment_method_name = null
      computedDataParams.value.empty = true
      computedDataParams.value.enabled = true
      computedDataParams.value.avatar = null
      computedDataParams.value.edited = false

      // recalculatePayment()
    }

    const onViewReceiptParticipant = async invoicePaymentId => {
      const resp = await getInvoicePayment(invoicePaymentId)
      if (resp.ok) {
        emit('viewReceipt', resp.data)
      }
    }

    const onDeleteMatchParticipant = async (userMatchId, removeSlot = true) => {
      if (userMatchId) {
        const resp = await removeUserMatch(userMatchId)
        if (resp.ok) {
          // success(resp.message)
          onClearContact()
          if (removeSlot) emit('removeParticipant', props.placeIndex)
        } else {
          // error(resp.message.text)
        }
      } else if (removeSlot) emit('removeParticipant', props.placeIndex)
    }

    const onShowOnsitePayment = (add = false) => {
      showOnsitePayment.value = true

      if (add) addGuest()
    }

    const initData = () => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
      phoneCode.value = initCode.value

      if ((computedEditMode.value || computedInitMode.value) && computedDataParams.value.id) {
        participantsSearched.value = []
        participantsSearched.value.push({
          id: computedDataParams.value.id,
          facility_id: computedDataParams.value.id,
          first_name: null,
          last_name: null,
          fullname: computedDataParams.value.fullname,
          phone_code: computedDataParams.value.phone_code,
          phone_prefix: computedDataParams.value.phone_prefix,
          phone_number: computedDataParams.value.phone_number,
          email: computedDataParams.value.email,
          status: computedDataParams.value.status,
          avatar: computedDataParams.value.avatar || null,
          user_fullname: null,
          user_app_id: null,
        })
      }
      if (!computedDataParams.value.id) participantsSearched.value = []

      showOnsitePayment.value = false

      if (computedDataParams.value.empty && computedDataParams.value.enabled && computedDataParams.value.remove_slot) {
        if (autoContactFocusTimer.value) clearTimeout(autoContactFocusTimer.value)
        autoContactFocusTimer.value = setTimeout(() => {
          const inputElement = document.getElementById(`autoContact-${props.placeIndex}`)
          inputElement.addEventListener('blur', () => {
            if (autoContactBlurTimer.value) clearTimeout(autoContactBlurTimer.value)
            autoContactBlurTimer.value = setTimeout(() => {
              if (computedDataParams.value.remove_slot && computedDataParams.value.empty && !isContactModalOpen.value) {
                onClearContact()
                emit('removeParticipant', props.placeIndex)
              }
            }, 300)
          })
          inputElement.click()
        }, 300)
      }
    }

    watch([isContactModalOpen], () => {
      if (!isContactModalOpen.value) onCancelContact()
    })

    watch(
      [computedIsOpen],
      () => {
        if (computedIsOpen.value) {
          if (computedDataParams.value.onsite_payment_method_id) {
            showOnsitePayment.value = true
          }
        } else {
          showOnsitePayment.value = false
          participantsSearched.value = []
        }
      },
      { immediate: true },
    )

    watch([computedInitMode], () => {
      if (computedInitMode.value) {
        initData()
      }
    })

    watch([computedRecurringType], () => {
      if (['F', 'flat'].includes(computedRecurringType.value) && props.placeIndex === 0 && typeof (computedDataParams.value.id) === 'string' && computedDataParams.value.id.includes('guest')) {
        onClearContact()
        participantsSearched.value = []
      }
    })

    onMounted(async () => {
      initData()
    })

    return {
      computedDataParams,
      computedEditMode,
      computedIsOpen,
      computedRecurringType,

      participantsSearched,
      contactSelectedId,
      searchQuery,
      searchLoading,

      isContactModalOpen,
      contactSelected,
      contactData,
      formContactDialog,
      autoContact,

      itemsMenu,

      phoneNumber,
      phoneCode,
      searchedUser,
      searchedUserError,
      searchedContact,
      timer,
      initCode,
      phoneCodeOptions,
      validatePhone,
      searchUser,

      resolveParticipantLabel,
      searchFilter,
      onSelectContact,
      addContact,
      addGuest,
      avatarText,
      onCreateContact,
      onCancelContact,
      onClearContact,
      onAddMatchParticipant,
      onViewReceiptParticipant,
      onDeleteMatchParticipant,
      recalculatePayment,

      showOnsitePayment,
      onSelectOnsitePaymentMethod,
      onShowOnsitePayment,

      required,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      emailValidator,
      onlyNumberDot,
      onlyNumbers,

      icons: {
        mdiDotsVertical,
        mdiPlusCircleOutline,
        mdiAccountOutline,
        mdiClose,
        mdiMenuDown,
      },
    }
  },
}
</script>
<style>
.v-list-item {
  height: 100% !important;
}
</style>
<style lang="scss">
.participant-label > .v-input__control > .v-input__slot > .v-text-field__slot label {
  left: 0 !important;
}
</style>
